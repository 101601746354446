import React from 'react';

import { Helmet } from 'react-helmet';
import { FAQ } from 'components/page-wrappers';

// tslint:disable no-default-export
export default () => {
  return (
    <>
      <Helmet title="Ruadeseo | Frequently Asked Questions">
        <link rel="canonical" href="https://www.valledeguadalupewinetours.com/faq" />
      </Helmet>
      <FAQ />
    </>
  );
};
